
.application-title {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgb(249 246 241/var(--tw-bg-opacity));
    color: rgb(254 46 60/var(--tw-text-opacity));
    justify-content: center;
    text-align: center;
    display: flex;
    margin: 0;
    padding: 0.8rem;
    border-bottom: salmon solid 4px;
    margin-bottom: 1rem;
    line-height: 1.3em;
    font-family: 'Nouvelle Grotesk',sans-serif;
    font-size: 1.8rem;
}

.application-form {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
}

label {
    text-align: left;
    padding-bottom: 0.5rem;
    font-size: 14px;
    font-weight: 500;
}

input {
    margin-bottom: 20px;
    padding:0.8rem;
    border-radius: 4px;
    border: solid 1px #c1ced5;
}

.radio {
    font-size: 14px;
    padding-top: 0.5rem;
    font-weight: 500;
}

input, select {
    font-size: 1.1rem;
}

input[type=radio] {
    padding-right: 2rem;
}

input[type=submit] {
    margin-bottom: 3rem;
    background-color: #053e6f;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
}

.submit-button {
    width: 100%;
    height: 3rem;
    border-radius: 5px;
}

select {
    margin-bottom: 20px;
    padding:0.7rem 0.5rem;
    border-radius: 4px;
    border: solid 1px #c1ced5;
    background-color: white;
    color:black;
}

#graduationYear {
    height: 3rem;
    width: 9rem;
}

#employmentStatus {
    height: 3rem;
}


input[type=file] {
    margin-bottom: 0.3rem;
}

.upload-button {
    padding: 0.5rem;
    align-self: left;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 8rem;
    height: 2.2rem;
    font-weight: 600;
    background-color: rgb(103 114 120);
    color: white;
    border-radius: 4px;
}

.file-error {
    color: red;
    font-size: 12px;
    margin-bottom: 0.8rem;
}


.email-notification-container {
    height: 100vh;
    padding: 6rem 1.5rem;
    text-align: center;
    background-color: #dcefff;
}
.email-error {
    padding: 5rem 3rem 0 3rem;
    text-align: center;
    background-color: #f5f9fb;
    height: 100vh;
    font-size: 1.1rem;
    color: red;
}
.email-success {
    color: #053988;
}
.email-message-title {
    font-size: 1.6rem;
    font-weight: 600;
}

.email-message {
    color: black;
    font-size: 1.2rem;
    line-height: 1.5rem;
}

@media only screen and (min-width: 992px) {

    .application-form {
        margin-left: 2rem;
    }


    input, select {
        width: 400px;
    }


input[type=radio] {
    width: unset;
}

    .submit-button {
        width: 300px;
    }

}