.nav-header {
    display: flex; 
    align-items: center;
    padding: 1.4rem 1rem;
}

a {
    text-decoration: none;
    color: #020a38;;
}

.logo {
    width: 60%;
    margin: 0;
}
.logo-text {
    margin: 0;
    font-size: 1.8rem;
    color: rgb(255, 255, 255);
    font-weight: 700;
}

.apply-now {
    width: 6.3rem;
    height: 35px;
    border-radius: 0.5rem;
    background-color:#fad062;
    font-size: .9rem;
    font-weight: 600;
    text-align: center;
    border: none;
    margin-left: auto;
    white-space: nowrap;
}
.logo-part-title {
    color: #02a9f7;
  }

  @media only screen and (min-width: 992px) {

    .logo-text {
        margin-left: 2rem;
    }   
    .apply-now {
        width: 7rem;
        height: 40px;
        margin-right: 2rem;
    }
  }

