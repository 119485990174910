.App {
  text-align: center;
}

.App-header {
  top: 0;
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;
  background-color: #001a31;
  text-align:center;
  width: 100%;
}

.container {
  padding: 90px 16px 30px 16px;
}

p {
  margin: 0;
}

.title {
  font-family: 'Mabry Pro',sans-serif;
  color:hwb(220deg 9% 25%);
  font-size: 1.6rem;
  letter-spacing: -1.8px;
  line-height: 40px;
  margin-bottom: 0.8rem;
}

p, pre {
  margin: 0;
}

.title-message {
  padding: 0 0.8rem 1rem 0.8rem;
  font-family: 'Nouvelle Grotesk',sans-serif;
  color: rgb(3,54,61);
  line-height: 2.6rem;
  text-align: center;
  line-height: 1.4rem;
  font-size: 16px;
}

img {
  margin: auto;
  display: block;
}

.message-card { 
  margin: 1rem .8rem 0 0.8rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #005a7d;
  padding: 1.8rem 1.8rem;
  border-radius: 1.6rem;
}
.card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Charter', serif;
  color:#fad062;
  font-size: 1.2rem;
  font-weight: 500;
}

.title-icon {
  margin-right: 0.5rem;
}

.card-text {
  font-size: 1rem;
  padding-top: 1rem;
  color: white;
  font-weight: 500;
  line-height: 1.4rem;
}

.bg-sv-burgundy {
  --tw-bg-opacity:1;
  background-color: rgb(98 6 57/var(--tw-bg-opacity));
}

.find-career, .launch-career {
  color: #005a7d;
  font-family: Mabry Pro,sans-serif;
  font-size: 1.8rem;
  padding-top: 1.2rem;
  /* padding-bottom: 1.2rem; */
  font-weight: 500;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  text-align: center;
}

.course-title {
  color: #f3c265;
}

.course-text {
  color: #ebeaea;
}

.role-text {
  color: white;
  font-weight: 600;
}

.what-it-takes, .admission {
  padding: 2.5rem .5rem 0 .5rem;
  color: rgb(3,54,61);
  font-family: 'Nouvelle Grotesk',sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  text-size-adjust: 100%;
}

.what-it-takes-response {
  padding: .6rem .5rem 0 .5rem;
  color: rgb(3,54,61);
  font-family: 'Nouvelle Grotesk',sans-serif;
  font-size: 16px;
  line-height: 22.8px;
  text-align: left;
  text-size-adjust: 100%;
}
.education {
  margin-bottom: 0.5rem;
}

.what-it-takes-full-time {
  margin-top: 1rem;
  padding: 1.5rem 1rem;
  background-color: #fad062;
  color: rgb(3,54,61);
  font-family: 'Nouvelle Grotesk',sans-serif;
  font-size: 16px;
  line-height: 22.8px;
  text-align: center;
  text-size-adjust: 100%;
  border-radius: 0.8rem;
}

.admission {
  margin-top: 1rem;
}

.circle-1 {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: white;
  font-weight: 800;
  background-color:#0b4d81;
}

.steps {
  margin: 1rem 0 0 0 ;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(249,246,241);
  color: rgb(21,100,113);
}

.step-text {
  color: rgb(3,54,61);
  padding: unset;
  padding-left: 1.6rem;
  font-size: 16px;
  line-height: 20.8px;
  text-align: left;
  text-size-adjust: 100%;
}

.we-invest-title {
  font-family: 'Mabry Pro',sans-serif;
  color:hwb(187deg 1% 52%);
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -1.5px;
  line-height: 50px;
  margin-top: 3rem;
  margin-bottom: 0.8rem;
}

.tution-refund, .training, .job-search, .on-job-coaching {
  margin-left: 0;
  margin-right: 0;
  background-color: #074f87;
  color: #fbfcff;
}

/* .tution-refund-title {
  color: #deb846;
} */

.training-title, .tution-refund-title, .job-search-title, .on-job-coaching-title {
  color: #fff27c;
}

/* .job-search-title {
  color:#fef8c1
}

.on-job-coaching-title {
  color:#0621ba
} */

.footer-container {
  margin-top: 0.5rem;
}

.footer {
  background-color: #001a31;
  font-weight: 700;
  text-align: left;
  padding: 1.6rem 1.6rem;
  /* margin-top: 2rem; */
}

.footer-title {
  font-size: 1.8rem;
  color: white;
}

.footer-part-title {
  color: #02a9f7;
}

.footer-mission {
  font-family: 'Charter', serif;
  font-size: 16px;
  font-style: oblique;
  font-weight: 600;
  line-height: 21.6px;
  color: ghostwhite;
  border-left: #fad062 solid 6px;
  padding-left: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.footer-address, .footer-contact, .footer-copyright {
  padding-bottom: 1.5rem;
  font-family: 'Nouvelle Grotesk',sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.6px;
  color: #eaecf7;
}

.footer-seperator {
  height: 2px;
  width: 100%;
  color: wheat
}

.footer-copyright {
  padding-top: 1rem;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.phone, .email {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}

.phone-icon, .email-icon {
  margin: unset;
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
}
.email-icon {
  /* margin-left: 1rem; */
  width: 2.3rem;
  height: 2.2rem;
} 
/* 
.phone, .email  {
  text-decoration:revert;
} */

.text-warm-light {
  --tw-text-opacity: 1;
  color: rgb(249 246 241/var(--tw-text-opacity));
}

.risk-free-container {
  display: none;
  padding: 2rem 16px 2rem 16px;
  --tw-bg-opacity: 1;
    background-color: rgb(98 6 57/var(--tw-bg-opacity));
    /* text-warm-light */
    --tw-text-opacity: 1;
    color: rgb(249 246 241/var(--tw-text-opacity));
}

.risk-free-title {
  font-family: Mabry Pro,sans-serif;
  font-size: 32px;
  --tw-text-opacity: 1;
  color: rgb(249 246 241/var(--tw-text-opacity));
  font-weight: 500;
}

.risk-free-message {
  --tw-text-opacity: 1;
  color: rgb(249 246 241/var(--tw-text-opacity));
}

.risk-free-training-tile-container .message-card {
  margin: unset;
  margin-bottom: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(249 246 241/var(--tw-bg-opacity));
}

.risk-free-training-tile-container .card-title {
  --tw-text-opacity: 1;
  color: rgb(98 6 57/var(--tw-text-opacity));
  font-family: Charter,serif;
}

.risk-free-container .card-text {
  --tw-text-opacity: 1;
    color: rgb(28 29 33/var(--tw-text-opacity));
}


@media only screen and (min-width: 992px) {

  .container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;  
  }

  .top-tile-container {
    display: flex;
    justify-content: center;
  }

  .top-tile-container .message-card {
    width: 33%;
  }

  .cousre-tile-container {
    display: flex;   
    justify-content: center;
  }


  .course-card {
    width: 50%;
  }

  .what-it-takes-container {
    margin-top: 2rem;
    vertical-align:top;
  }

  .what-it-takes, .what-it-takes-full-time {
    padding-top: 1rem;
  }

  .what-it-takes-full-time {
    background-color: wh;
  }

  .admission-steps-container {
    display: flex;
  }

  .steps {
    width: 33%;
  }

  .admission-steps-container .steps:first-child, .steps:nth-child(2) {
    margin-right: 0.5rem;
  }

  .tution-coverage-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .tution-coverage-container .message-card {
    width: 48%;
  }

  .tution-coverage-container .message-card:nth-child(odd){
    margin-right: 1rem;
  }

  .risk-free-training-tile-container, .risk-free-message {
    width: 65%;
    margin-left: 17.5%;
    justify-content: center;
  }

  .footer-container {
    margin: unset;
  }

  .footer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

}

.attention-banner {
  background-color: #FFC107;
  color: rgb(3,54,61);
  padding: 20px;
  text-align: center;
  /* position: fixed; */
  /* bottom: 0; */
  left: 0;
  width: 100%;
  z-index: 999;
  animation: slideUp 0.5s ease-in-out;
}

.banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

h2 {
  font-size: 24px;
  margin: 0;
}

.banner-text {
  font-size: 16px;
  margin: 10px 0 0 0;
}

.download_webdev, .download_rpa {
  background-color: #f3c265;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;  
}
